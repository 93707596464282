@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: fallback;
  font-style: normal;
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: fallback;
  font-style: normal;
  unicode-range: U+0020-007F;
}

.HW_badge_cont {
  .HW_badge {
    left: -5px;
  }
}
.mentionss {
  color: #5e5e73;
  font-weight: 600;
  text-decoration: none;
}
.emojiSuggestion {
  background-color: white;
  border: 1px solid #ededf2;
  position: absolute;
  right: -30px;
}
.taskMention {
  font-weight: 500;
  text-decoration: none;
}

.required {
  color: #d52a2a;
}
