@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('@assets/fonts/WorkSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('@assets/fonts/WorkSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('@assets/fonts/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('@assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: bold;
  src: url('@assets/fonts/WorkSans-Bold.ttf') format('truetype');
}

.k-pdf-export {
  font-family: 'Roboto', sans-serif;
}

.k-pdf-export p {
  font-family: 'Roboto', sans-serif;
}
